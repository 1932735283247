import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node = _resolveComponent("node", true)!

  return (_openBlock(), _createElementBlock("ul", null, [
    _createElementVNode("li", null, [
      _createElementVNode("a", {
        href: $props.route.path
      }, _toDisplayString($props.route.meta.title), 9, _hoisted_1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getChildRoutes(), (route) => {
        return (_openBlock(), _createElementBlock("div", { key: route }, [
          _createVNode(_component_node, {
            route: route,
            routes: $props.routes
          }, null, 8, ["route", "routes"])
        ]))
      }), 128))
    ])
  ]))
}