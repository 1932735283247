import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_quadruple_block = _resolveComponent("quadruple-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { title: "Nieuws" }),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_quadruple_block, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("h2", null, "Nieuwe website", -1),
            _createElementVNode("p", null, " Zoals u wellicht al is opgevallen, heeft ComputerInfor een nieuwe website. De vorige website maakte gebruik van verouderde technieken, die het onderhoud bemoeilijkte. Daarom is de website volledig opnieuw gebouwd met gebruik van de laatste technieken. Ook wordt de website in eigen beheer op een server gehost, zodat updates snel doorgevoerd kunnen worden. De website is volledig gebouwd en ontworpen door ComputerInfor. ", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_quadruple_block, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("h2", null, "Uitbreiding onderneming", -1),
            _createElementVNode("p", null, " ComputerInfor heeft vanaf heden twee dochterondernemingen: MaatComputers en MaatOntwikkeling. MaatComputers richt zich op computers en MaatOntwikkeling richt zich op softwareontwikkeling. De twee dochterondernemingen zijn in het leven geroepen om het totale aanbod van diensten overzichtelijker te maken. ", -1)
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}