import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_notice_alert = _resolveComponent("notice-alert")!
  const _component_single_block = _resolveComponent("single-block")!
  const _component_contact_details_table = _resolveComponent("contact-details-table")!
  const _component_triple_block = _resolveComponent("triple-block")!
  const _component_opening_hours_table = _resolveComponent("opening-hours-table")!
  const _component_contact_form = _resolveComponent("contact-form")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { title: "Contact" }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_single_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_notice_alert, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Bezoek op locatie is enkel mogelijk middels een afspraak. ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_triple_block, null, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Contactgegevens", -1)),
            _createVNode(_component_contact_details_table)
          ]),
          _: 1
        }),
        _createVNode(_component_triple_block, null, {
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Contacturen", -1)),
            _createVNode(_component_opening_hours_table)
          ]),
          _: 1
        }),
        _createVNode(_component_triple_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.contactImage }, null, 8, _hoisted_3)
          ]),
          _: 1
        }),
        _createVNode(_component_single_block, null, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Contactformulier", -1)),
            _createVNode(_component_contact_form, null, {
              draft: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("p", null, " Heeft u vragen of opmerkingen? Middels het contactformulier kunt u vrijblijvend een bericht versturen. ComputerInfor helpt u graag verder! ", -1)
              ])),
              sent: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("p", null, [
                  _createTextVNode(" Bedankt voor uw bericht. "),
                  _createElementVNode("br"),
                  _createTextVNode(" ComputerInfor neemt zo spoedig mogelijk contact met u op. Normaliter kunt u een reactie binnen 24 uur verwachten. ")
                ], -1),
                _createElementVNode("p", null, [
                  _createTextVNode(" ComputerInfor, "),
                  _createElementVNode("br"),
                  _createTextVNode(" goed geïnformeerd. ")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout, {
      displayOpeningHours: false,
      displayContactDetails: false
    })
  ], 64))
}