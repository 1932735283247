import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_page_button = _resolveComponent("page-button")!
  const _component_double_block = _resolveComponent("double-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { title: "Dochterondernemingen" }),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_button, {
              title: "MaatComputers",
              path: "http://www.maatcomputers.nl",
              external: true
            }),
            _createElementVNode("img", { src: $data.maatcomputersImage }, null, 8, _hoisted_2),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, " MaatComputers doet vrijwel alles wat met computers te maken heeft: vervaardiging, revisie, onderhoud, aansluiting, advies, verkoop en nog veel meer. U kunt hier terecht voor al uw computergerelateerde problemen. ", -1))
          ]),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_button, {
              title: "MaatOntwikkeling",
              path: "http://www.maatontwikkeling.nl",
              external: true
            }),
            _createElementVNode("img", { src: $data.maatontwikkelingImage }, null, 8, _hoisted_3),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, " MaatOntwikkeling specialiseert zich in ontwikkeling en onderhoud van kleinschalige software applicaties: websites, webapplicaties, Windows applicaties en nog veel meer. ", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}