import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_single_block = _resolveComponent("single-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_header_layout, {
      title: "Algemene voorwaarden",
      subtitle: "Heldere afspraken voor alle betrokken partijen"
    }),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_single_block, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "LAATST BIJGEWERKT OP 04-02-2018")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("em", null, "In de algemene voorwaarden worden de volgende begrippen verstaan: ")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Opdrachtgever:"),
              _createTextVNode(" De partij die de opdracht geeft, deze partij wordt in dit artikel vanaf nu met de benaming “klant” aangeroepen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Opdrachtnemer:"),
              _createTextVNode(" De partij die de opdracht uitvoert, deze partij wordt in dit artikel vanaf nu met de benaming “ComputerInfor” aangeroepen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Werkdagen:"),
              _createTextVNode(" Alle dagen met uitzondering van 1 januari, 1e Paasdag, 2e Paasdag, Hemelvaartsdag, 1e Pinksterdag, 2e Pinksterdag, 1e Kerstdag en 2e Kerstdag.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Dagen:"),
              _createTextVNode(" Alle kalenderdagen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Opdracht c.q. Overeenkomst:"),
              _createTextVNode(" De overeenkomst van opdracht op grond waarvan de klant zich tegen betaling van honorarium en kosten jegens ComputerInfor verbindt werkzaamheden voor de klant te verrichten. Het bepaalde in de artikelen 7:404 en 7:407 lid 2 BW is niet van toepassing.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "1 - OFFERTES ")
              ])
            ], -1),
            _createElementVNode("p", null, "Alle offertes, waarbij niet uitdrukkelijk het tegendeel is vermeld, gelden als een vrijblijvend aanbod dat ook na aanvaarding kan worden herroepen. Indien deze herroeping niet binnen 6 werkdagen na de aanvaarding geschiedt, is de overeenkomst tot stand gekomen.", -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "2 - TOTSTANDKOMING VAN DE OVEREENKOMST ")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "2.1"),
              _createTextVNode(" De Overeenkomst wordt gevormd door deze algemene voorwaarden tezamen met de opdrachtbevestiging en komt tot stand op het moment dat de opdrachtbevestiging door de klant digitaal retour is ontvangen en/of de door ComputerInfor en de klant ondertekende opdrachtbevestiging door ComputerInfor retour is ontvangen. Zolang de opdrachtbevestiging niet retour is ontvangen, behoudt ComputerInfor zich het recht voor haar (personeels-)capaciteit elders in te zetten. De opdrachtbevestiging is gebaseerd op de ten tijde daarvan door De klant aan ComputerInfor verstrekte informatie. De opdrachtbevestiging wordt geacht de Overeenkomst juist en volledig weer te geven.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "2.2"),
              _createTextVNode(" De Overeenkomst komt in de plaats van, en vervangt, alle eerdere voorstellen, correspondentie, afspraken of andere communicatie, schriftelijk dan wel mondeling gedaan.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "2.3"),
              _createTextVNode(" De Overeenkomst wordt aangegaan voor onbepaalde tijd, tenzij uit de inhoud, aard of strekking van de verleende opdracht voortvloeit dat deze voor een bepaalde tijd is aangegaan.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "2.4"),
              _createTextVNode(" Elke tussen de klant en ComputerInfor gesloten overeenkomst is voor beide partijen volledig bindend, tenzij ComputerInfor de klant binnen 5 werkdagen na totstandkoming van de overeenkomst gemotiveerd schriftelijk bericht dat hij de overeenkomst ontbindt.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "3 - MEDEWERKING DOOR DE KLANT")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.1"),
              _createTextVNode(" De klant dient er voor zorg te dragen dat alle gegevens en bescheiden, welke ComputerInfor overeenkomstig zijn oordeel nodig heeft voor het correct en tijdig uitvoeren van de verleende Opdracht, tijdig en in de door ComputerInfor gewenste vorm en wijze aan ComputerInfor ter beschikking worden gesteld.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.2"),
              _createTextVNode(" De klant dient er voor zorg te dragen dat ComputerInfor onverwijld wordt geïnformeerd over feiten en omstandigheden die in verband met de correcte uitvoering van de opdracht van belang kunnen zijn.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.3"),
              _createTextVNode(" Tenzij uit de aard van de Opdracht anders voortvloeit is de klant verantwoordelijk voor de juistheid, volledigheid en betrouwbaarheid van de aan ComputerInfor ter beschikking gestelde gegevens en bescheiden, ook indien deze via of van derden afkomstig zijn.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.4"),
              _createTextVNode(" De klant dient er voor zorg te dragen dat ComputerInfor wordt voorzien van kantoorruimte en overige faciliteiten die naar het oordeel van ComputerInfor noodzakelijk of nuttig zijn om de overeenkomst uit te voeren en die voldoen aan alle daaraan te stellen (wettelijke) vereisten. Hieronder wordt onder meer begrepen het gebruik van computer-, telefoon- en faxfaciliteiten. Met betrekking tot beschikbaar gestelde (computer)faciliteiten, is de klant verplicht voor continuïteit zorg te dragen onder meer door middel van afdoende back-up, veiligheid en viruscontrole procedures.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.5"),
              _createTextVNode(" Tenzij uit de aard van de Opdracht anders voortvloeit zal de klant het door ComputerInfor noodzakelijk geachte personeel inzetten dan wel laten inzetten teneinde ComputerInfor in staat te stellen de werkzaamheden te verrichten. Indien specifiek personeel noodzakelijk is, zal dit worden overeengekomen en vastgelegd in de opdrachtbevestiging. De klant dient er voor te zorgen dat zijn personeel over de juiste vaardigheden en ervaring beschikt om de werkzaamheden te kunnen")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "3.6"),
              _createTextVNode(" De uit de vertraging in de uitvoering van de Opdracht voort vloeiende extra kosten en extra honoraria, ontstaan door het niet, niet tijdig of niet behoorlijk ter beschikking stellen van de verzochte gegevens, bescheiden, faciliteiten en/of personeel zijn voor rekening van De klant.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "4 - UITVOERING VAN DE OPDRACHT")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "4.1"),
              _createTextVNode(" Alle werkzaamheden die door ComputerInfor worden verricht, worden uitgevoerd naar zijn beste inzicht en vermogen overeenkomstig de eisen van goed vakmanschap. Ten aanzien van de beoogde werkzaamheden is sprake van een inspanningsverbintenis aan de zijde van ComputerInfor, tenzij uitdrukkelijk anders is bepaald.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "4.2"),
              _createTextVNode(" ComputerInfor bepaalt de wijze waarop en door welke medewerk(st)er(s) de verleende opdracht wordt uitgevoerd, doch neemt daarbij de door De klant kenbaar gemaakte eisen zoveel mogelijk in acht. Indien in de opdrachtbevestiging (een) medewerker(s) met naam word(t)(en) genoemd, zal ComputerInfor zich inspannen om te bewerkstelligen dat de betreffende medewerker(s) gedurende de gehele looptijd van de opdracht beschikbaar blijft (blijven) voor het verrichten van de werkzaamheden. Niettegenstaande dit voorgaande heeft ComputerInfor het recht om dergelijke medewerkers na overleg met de klant te vervangen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "4.3"),
              _createTextVNode(" ComputerInfor kan eerst meer werkzaamheden verrichten en aan de klant in rekening brengen dan waartoe de opdracht is verstrekt, indien de klant hiervoor vooraf toestemming heeft verleend. Indien ComputerInfor echter uit hoofde van zijn (wettelijke) zorgplicht gehouden is om meerwerk te verrichten, is hij gerechtigd dit aan de klant in rekening te brengen, ook indien de klant voor het verrichten van het meerwerk vooraf niet expliciet zijn toestemming heeft verleend.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "4.4"),
              _createTextVNode(" Indien de klant derden bij de uitvoering van de opdracht wenst te betrekken, zal hij daartoe slechts overgaan na daarover met ComputerInfor overeenstemming te hebben bereikt, daar het op directe of indirecte wijze betrekken van een derde bij de uitvoering van de opdracht belangrijke invloed kan hebben op de mogelijkheden van ComputerInfor om de opdracht correct uit te voeren. Het bepaalde in de vorige volzin is van overeenkomstige toepassing op ComputerInfor.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "4.5"),
              _createTextVNode(" ComputerInfor houdt terzake van de opdracht een werkdossier aan met daarin kopieën van relevante stukken, dat eigendom is van ComputerInfor.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "5 - GEHEIMHOUDING")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "5.1"),
              _createTextVNode(" Tenzij enige wetsbepaling, voorschrift of andere (beroeps)regel haar daartoe verplicht, is ComputerInfor/zijn de door ComputerInfor ingezette medewerk(st)er(s) verplicht tot geheimhouding tegenover derden ten aanzien van vertrouwelijke informatie die is verkregen van de klant. De klant kan ter zake ontheffing verlenen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "5.2"),
              _createTextVNode(" Behoudens schriftelijke toestemming van de klant is ComputerInfor niet gerechtigd de vertrouwelijke informatie die aan haar door de klant ter beschikking wordt gesteld aan te wenden tot een ander doel dan waarvoor zij werd verkregen. Hierop wordt echter een uitzondering gemaakt in het geval ComputerInfor voor zichzelf optreedt in een civiele- of strafprocedure waarbij deze informatie van belang kan zijn.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "5.3"),
              _createTextVNode(" Tenzij sprake is van enige wetsbepaling, voorschrift of andere regel die de klant verplicht tot openbaarmaking of daartoe door ComputerInfor voorafgaande schriftelijke toestemming is verleend, zal de klant de inhoud van rapporten, adviezen of andere al dan niet schriftelijke uitingen van ComputerInfor, niet aan derden openbaren.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "5.4"),
              _createTextVNode(" ComputerInfor en de klant zullen hun verplichtingen op grond van dit artikel opleggen aan door hen in te schakelen derden.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "5.5"),
              _createTextVNode(" Als niet in strijd geacht zijnde met het bepaalde in artikel 5.1 en 5.2, is ComputerInfor gerechtigd tot het vermelden in hoofdlijnen van de verrichte werkzaamheden aan (potentiële) klanten van ComputerInfor en slechts ter indicatie van de ervaring van ComputerInfor.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "6 - INTELLECTUELE EIGENDOM")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "6.1"),
              _createTextVNode(" ComputerInfor behoudt zich alle rechten van intellectuele eigendom voor met betrekking tot producten van de geest welke hij gebruikt of heeft gebruikt en/of ontwikkelt en/of heeft ontwikkeld in het kader van de uitvoering van de opdracht, en ten aanzien waarvan hij de auteursrechten of andere rechten van intellectuele eigendom heeft of geldend kan maken.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "6.2"),
              _createTextVNode(" Het is de klant uitdrukkelijk verboden die producten, waaronder mede begrepen computerprogramma's, systeemontwerpen, werkwijzen, adviezen, (model) contracten en andere geestesproducten van ComputerInfor, een en ander in de ruimste zin des woords, al dan niet met inschakeling van derden te verveelvoudigen, te open- baren of te exploiteren. Verveelvoudiging en/of openbaarmaking en/of exploitatie is slechts na verkregen schriftelijke toestemming van ComputerInfor toegestaan. De klant heeft het recht de schriftelijke documenten te vermenigvuldigen voor gebruik binnen zijn eigen organisatie, voor zover passend binnen het doel van de opdracht. Ingeval van tussentijdse beëindiging van de opdracht, is het voorgaande van overeenkomstige toepassing.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "7 - HONORARIUM")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "7.1"),
              _createTextVNode(" Indien na de totstandkoming van de Overeenkomst, doch voordat de opdracht geheel is uitgevoerd, tariefbepalende factoren zoals bijvoorbeeld lonen en/of prijzen een wijziging ondergaan, is ComputerInfor gerechtigd het eerder overeengekomen tarief dienovereenkomstig aan te passen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "7.2"),
              _createTextVNode(" Het honorarium van ComputerInfor is exclusief reiskosten en onkosten van ComputerInfor en exclusief declaraties van door ComputerInfor ingeschakelde derden.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "7.3"),
              _createTextVNode(" Alle tarieven zijn exclusief omzetbelasting en andere heffingen welke van overheidswege (kunnen) worden opgelegd.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "8 - BETALING")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "8.1"),
              _createTextVNode(" Betaling door de klant dient, zonder aftrek, korting of schuldverrekening te geschieden binnen 14 dagen na factuurdatum. Betaling dient te geschieden in op de factuur aangegeven valuta, door middel van overmaking ten gunste van een door ComputerInfor aan te wijzen bankrekening. Bezwaren tegen de hoogte van de ingediende facturen schorten de betalingsverplichting van de klant niet op.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "8.2"),
              _createTextVNode(" Bij overschrijding van de onder 8.1 genoemde termijn, zal de opdracht komen te vervallen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "9 - LEVERINGSTERMIJN")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "9.1"),
              _createTextVNode(" Is de klant een vooruitbetaling verschuldigd, of dient hij voor de uitvoering benodigde informatie en/of materialen ter beschikking te stellen, dan gaat de termijn waarbinnen de werkzaamheden dienen te worden afgerond niet eerder in dan dat de betaling geheel door ComputerInfor is ontvangen, respectievelijk de informatie en/of materialen geheel aan haar ter beschikking is/zijn gesteld.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "9.2"),
              _createTextVNode(" Omdat de duur van de Opdracht kan worden beïnvloed door allerlei factoren, zoals de kwaliteit van de informatie die de klant verstrekt en de medewerking die wordt verleend, zijn de termijnen waarbinnen de werkzaamheden dienen te zijn afgerond, slechts te beschouwen als fatale termijnen indien dit uitdrukkelijk schriftelijk is overeengekomen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "9.3"),
              _createTextVNode(" De overeenkomst kan, tenzij vaststaat dat uitvoering blijvend onmogelijk is, door de klant niet wegens termijnoverschrijding worden ontbonden, tenzij ComputerInfor de overeenkomst ook niet of niet geheel uitvoert binnen een aan haar na afloop van de overeengekomen leveringstermijn schriftelijk aangezegde redelijke termijn. Ontbinding is dan toegestaan conform artikel 265 Boek 6 van het Burgerlijk Wetboek.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "10 – GARANTIE")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "10.1"),
              _createTextVNode(" Indien de overeenkomst tot stand is gekomen, geldt de standaard fabrieksgarantie die met alle producten wordt meegeleverd aan de klant. ComputerInfor is ten alle tijden verplicht de fabrieksgarantie te leveren aan de klant, indien dit mogelijk is.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "10.2"),
              _createTextVNode(" De garantie, die ComputerInfor aanbiedt, bestaat uit vervanging/reparatie van defecte onderdelen en verlening van service, indien een geleverd systeem niet naar behoren functioneert (met uitzondering van producten waarop een licentie rust), op kosten van ComputerInfor.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "10.3"),
              _createTextVNode(" ComputerInfor levert geen garantie in geval van verlies, diefstal of schade door onjuist gebruik van het product.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "10.4"),
              _createTextVNode(" De garantietermijn wordt in de overeenkomst vermeld en gaat in op de dag dat het product bij de klant is geleverd. Indien de garantietermijn is verlopen, is ComputerInfor niet verplicht om garantie te verlenen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "10.5"),
              _createTextVNode(" Garantie die ComputerInfor verleent aan de klant, is beperkt. Indien eventuele garantiezegels op een product zijn beschadigd, heeft Computerinfor het recht om geen garantie te verlenen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "11 - OPZEGGING")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "11.1 "),
              _createTextVNode("De klant en ComputerInfor kunnen de overeenkomst te allen tijde (tussentijds) per aangetekend schrijven opzeggen met inachtneming van een redelijke opzegtermijn, tenzij de redelijkheid en billijkheid zich tegen beëindiging of beëindiging op een dergelijke termijn verzetten.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "11.2 "),
              _createTextVNode("De overeenkomst mag door ieder der partijen per aangetekend schrijven (tussentijds) worden opgezegd zonder inachtneming van een opzegtermijn in het geval de andere partij niet in staat is om haar schulden te voldoen of indien een curator, bewindvoerder of vereffenaar is benoemd, de andere partij een schuldsanering treft, of om enige andere reden haar activiteiten staakt of indien de andere partij het ontstaan van een van de bovengenoemde omstandigheden bij de ene partij redelijkerwijs aannemelijk acht of indien er een situatie is ontstaan die onmiddellijke beëindiging rechtvaardigt in het belang van de opzeggende partij.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "11.3 "),
              _createTextVNode("Indien tot (tussentijdse) opzegging is overgegaan door de klant, heeft ComputerInfor recht op vergoeding van het aan haar zijde ontstane en aannemelijk te maken bezettingsverlies alsmede van additionele kosten die zij redelijkerwijs moet maken ten gevolge van de vroegtijdige beëindiging van de overeenkomst (zoals onder meer kosten met betrekking tot onder aanneming), tenzij er feiten en omstandigheden aan de opzegging ten grondslag liggen die aan ComputerInfor zijn toe te rekenen. Indien tot (tussentijdse) opzegging is overgegaan door ComputerInfor, heeft de klant recht op medewerking van ComputerInfor bij overdracht van werkzaamheden aan derden, tenzij er feiten en omstandigheden aan die opzegging ten grondslag liggen die aan de klant zijn toe te rekenen. ComputerInfor behoudt in alle gevallen van (tussentijdse) opzegging aanspraak op betaling van de declaraties voor door haar tot dan toe verrichte werkzaamheden, waarbij aan de de klant onder voorbehoud de voorlopige resultaten van de tot dan toe verrichte werkzaamheden ter beschikking zullen worden gesteld. Voor zover de overdracht van de werkzaamheden voor ComputerInfor extra kosten met zich meebrengt, worden deze aan de klant in rekening gebracht.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "11.4"),
              _createTextVNode(" Bij beëindiging van de overeenkomst dient ieder der partijen alle in haar bezit zijnde goederen, zaken en documenten die in eigendom toebehoren aan de andere partij onverwijld aan die andere partij ter hand te stellen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "12 - AANSPRAKELIJKHEID")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.1"),
              _createTextVNode(" ComputerInfor zal haar werkzaamheden naar beste kunnen verrichten en daarbij de zorgvuldigheid in acht nemen die van ComputerInfor kan worden verwacht. Indien een fout wordt gemaakt doordat de klant aan ComputerInfor onjuiste of onvolledige informatie heeft verstrekt, is ComputerInfor voor de daardoor ontstane schade niet aansprakelijk. Indien de klant aantoont dat zij schade heeft geleden door een fout van ComputerInfor die bij zorgvuldig handelen zou zijn vermeden, is ComputerInfor slechts voor directe schade aansprakelijk tot maximaal een bedrag van 1000 euro.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.2"),
              _createTextVNode(" Onder directe schade wordt uitsluitend verstaan: - De redelijke kosten ter vaststelling van de oorzaak en de omvang van de schade, voor zover de vaststelling betrekking heeft op schade in de zin van deze voorwaarden. - De eventuele redelijke kosten gemaakt om de gebrekkige prestatie van ComputerInfor aan de overeenkomst te laten beantwoorden, voor zoveel deze aan ComputerInfor toegerekend kunnen worden. -Redelijke kosten, gemaakt ter voorkoming of beperking van schade, voor zover de de klant aantoont dat deze kosten hebben geleid tot beperking van directe schade als bedoeld in deze algemene voorwaarden.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.3"),
              _createTextVNode(" ComputerInfor is nimmer aansprakelijk voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst, gemiste besparingen, schade door bedrijfsstagnatie, kosten voortvloeiende uit veroordeling in proceskosten, rente- en/of vertragingsschade, schade als gevolg van het verschaffen van gebrekkige medewerking en/of informatie van de klant, en/of schade wegens door ComputerInfor gegeven vrijblijvende inlichtingen of adviezen waarvan de inhoud niet uitdrukkelijk onderdeel van de schriftelijke overeenkomst vormt.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.4"),
              _createTextVNode(" De klant vrijwaart ComputerInfor voor vorderingen van derden wegens schade die veroorzaakt is doordat de klant aan ComputerInfor onjuiste of onvolledige informatie heeft verstrekt, tenzij de klant aantoont dat de schade geen verband houdt met verwijtbaar handelen of nalaten die haar is toe te rekenen dan wel is veroorzaakt door opzet of daarmee gelijk te stellen grove nalatigheid van ComputerInfor en tenzij enige dwingende (inter)nationale wet of regelgeving een dergelijke bepaling niet toestaat.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.5"),
              _createTextVNode(" De in lid 1 van dit artikel neergelegde aansprakelijkheidsbeperking wordt mede bedongen ten behoeve van de door ComputerInfor voor de uitvoering van de opdracht ingeschakelde derden.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "12.6"),
              _createTextVNode(" ComputerInfor is niet aansprakelijk voor beschadiging of teniet gaan van bescheiden tijdens vervoer of tijdens verzending per post, ongeacht of het vervoer of de verzending geschiedt door of namens de klant, ComputerInfor of derden.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "13 - CONTRACTSOVERNEMING ")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "13.1"),
              _createTextVNode(" Het is de klant niet toegestaan (enige verplichting uit) de overeenkomst over te dragen aan derden, tenzij ComputerInfor hiermee uitdrukkelijk akkoord gaat. ComputerInfor is gerechtigd aan deze toestemming voorwaarden te verbinden. De klant verbindt zich in ieder geval om alsdan alle ter zake relevante (betalings)verplichtingen uit de overeenkomst in deze algemene voorwaarden aan de derde op te leggen. De klant blijft te allen tijde naast deze derde aansprakelijk voor de verplichtingen uit de overeenkomst en de algemene voorwaarden, tenzij partijen expliciet anders overeenkomen.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "13.2"),
              _createTextVNode(" In geval van contractsoverneming vrijwaart de klant ComputerInfor ter zake van alle aanspraken van derden die mochten ontstaan ten gevolge van een niet of onjuist nakomen van enige verplichting door De klant uit de overeenkomst en/of deze algemene voorwaarden, tenzij enige dwingende (inter)nationale wet of regelgeving een dergelijke bepaling niet toestaat.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "14 - INTERNETGEBRUIK ")
              ])
            ], -1),
            _createElementVNode("p", null, "Tijdens de uitvoering van de opdracht zullen de klant en ComputerInfor op verzoek van één van hen door middel van elektronische maiI met elkaar kunnen communiceren. Zowel ComputerInfor als de klant erkennen dat aan het gebruik van elektronische mail risico's leven zoals vertraging en virus. De klant en ComputerInfor stellen hierbij vast jegens elkaar niet aansprakelijk te zullen zijn voor schade die eventueel voortvloeit bij één of ieder van hen tengevolge van het gebruik van elektronische mail. Zowel de klant als ComputerInfor zullen al hetgeen redelijkerwijs van ieder van hen verwacht mag worden doen of nalaten ter voorkoming van het optreden van voornoemde risico's. In het geval van twijfel inzake de juistheid van de door de klant of ComputerInfor ontvangen mail, is de inhoud van de door de verzender verzonden mail bepalend.", -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "15 - VERVALTERMIJN ")
              ])
            ], -1),
            _createElementVNode("p", null, "Voor zover in de overeenkomst niet anders is bepaald, vervallen vorderingsrechten en andere bevoegdheden van de klant uit welken hoofde ook jegens ComputerInfor in ieder geval na het verstrijken van één jaar vanaf het moment waarop zich een feit voordoet dat de klant deze rechten en/of bevoegdheden jegens ComputerInfor kan aanwenden.", -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "16 - NAWERKING ")
              ])
            ], -1),
            _createElementVNode("p", null, "De bepalingen van deze overeenkomst, waarvan het uitdrukkelijk of stilzwijgend de bedoeling is dat zij ook na beëindiging van deze overeenkomst van kracht blijven, zullen nadien van kracht blijven en partijen beiden blijven binden.", -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, [
                _createElementVNode("span", { style: {"font-size":"14.0pt","line-height":"115%"} }, "17 - TOEPASSELIJK RECHT EN FORUMKEUZE ")
              ])
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "17.1"),
              _createTextVNode(" Op alle overeenkomsten tussen de klant en ComputerInfor is Nederlands recht van toepassing.")
            ], -1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, "17.2"),
              _createTextVNode(" Inzake alle uit deze overeenkomst voortvloeiende geschillen is bevoegd de rechter van de woonplaats van de ComputerInfor, tenzij op grond van de wet een andere rechter dwingend rechtelijk bevoegd is.")
            ], -1)
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ]))
}