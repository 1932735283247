import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_double_block = _resolveComponent("double-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { title: "Portfolio" }),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("h2", null, "Voorstellen", -1),
            _createElementVNode("p", null, " Beste bezoeker, ", -1),
            _createElementVNode("p", null, " Van jongs af aan ben ik geïnteresseerd in techniek. Voornamelijk computers hebben mij altijd ontzettend geboeid. Op mijn 14e kreeg ik mijn eerste eigen computer voor school. Uiteraard was het spelen van spelletjes op de computer veel leuker dan waarvoor de computer oorspronkelijk bedoeld was: schoolwerk. Al snel merkte ik op dat de computer voor veel moderne spellen te langzaam was, waarop ik besloot de computer sneller te maken door betere hardware te installeren. Dit heeft destijds de hobby aangewakkerd die ik inmiddels ook mijn werk kan noemen. ", -1),
            _createElementVNode("p", null, " Na de middelbare school wilde ik graag iets gaan studeren wat te maken heeft met computers. Mijn oog viel op de opleiding Technische Informatica. Deze opleiding richt zich hoofdzakelijk op de ontwikkeling van software voor allerlei toepassingen. Alhoewel deze tak van de ICT compleet anders is dan hetgeen waar ik mijn voorkennis mee heb opgedaan, vind ik het programmeren van software ook ontzettend leuk om te doen. In 2020 heb ik mijn Bachelor of Science gehaald aan Avans Hogeschool Breda. Sindsdien ben ik werkzaam als voltijd Software Engineer en deeltijd ZZP'er. ", -1),
            _createElementVNode("p", null, " Door mijn kennis van zowel hardware als software heb ik een voorsprong op de gemiddelde \"computerspecialist\". Maak snel een afspraak en laat mij u overtuigen. Ik ben u graag van dienst! ", -1),
            _createElementVNode("p", null, [
              _createElementVNode("br"),
              _createTextVNode(" Met vriendelijke groet, ")
            ], -1),
            _createElementVNode("p", null, " Tom Remeeus ", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.founderImage }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}