import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sent"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  ref: "name",
  type: "text",
  id: "name"
}
const _hoisted_4 = {
  ref: "mail",
  type: "text",
  id: "mail"
}
const _hoisted_5 = {
  ref: "message",
  id: "message"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["onclick"]
const _hoisted_9 = ["onclick"]
const _hoisted_10 = { key: 3 }
const _hoisted_11 = ["onclick"]
const _hoisted_12 = ["onclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_success_alert = _resolveComponent("success-alert")!
  const _component_error_alert = _resolveComponent("error-alert")!
  const _component_notice_alert = _resolveComponent("notice-alert")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    class: _normalizeClass({ 'validated-input': $data.isValidated })
  }, [
    ($data.isSent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "sent", {}, undefined, true),
          _createVNode(_component_success_alert, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Het bericht is succesvol verzonden. ")
            ])),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "draft", {}, undefined, true),
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "name" }, "Naam:", -1)),
          _createElementVNode("input", _hoisted_3, null, 512),
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "mail" }, "E-mailadres:", -1)),
          _createElementVNode("input", _hoisted_4, null, 512),
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "message" }, "Bericht:", -1)),
          _createElementVNode("textarea", _hoisted_5, null, 512),
          ($data.error)
            ? (_openBlock(), _createBlock(_component_error_alert, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.error), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($data.isSending)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_notice_alert, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Het bericht wordt verzonden. ")
                  ])),
                  _: 1
                })
              ]))
            : ($data.isValidated)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_notice_alert, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Controleer of alles naar wens is ingevuld. ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("button", {
                    onclick: $options.edit,
                    type: "button"
                  }, " Bewerken ", 8, _hoisted_8),
                  _createElementVNode("button", {
                    onclick: $options.send,
                    type: "button"
                  }, " Verzenden ", 8, _hoisted_9)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("button", {
                    onclick: $options.cancel,
                    type: "button"
                  }, " Annuleren ", 8, _hoisted_11),
                  _createElementVNode("button", {
                    onclick: $options.validate,
                    type: "button"
                  }, " Controleren ", 8, _hoisted_12)
                ]))
        ]))
  ], 2))
}