import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "introduction" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_banner_layout = _resolveComponent("banner-layout")!
  const _component_search_form = _resolveComponent("search-form")!
  const _component_double_block = _resolveComponent("double-block")!
  const _component_notice_alert = _resolveComponent("notice-alert")!
  const _component_single_block = _resolveComponent("single-block")!
  const _component_page_button = _resolveComponent("page-button")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, {
      displayBreadcrumb: false,
      displaySearchForm: false
    }),
    _createVNode(_component_banner_layout, {
      slogan: "Service en kwaliteit voor een eerlijke prijs.",
      subtitle: "ComputerInfor, goed geïnformeerd."
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_search_form)
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.experienceImage }, null, 8, _hoisted_5),
            _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Ervaring", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, " Al sinds 2014 is ComputerInfor het juiste adres voor vervaardiging, reparatie en onderhoud van computers. In 2019 zijn de werkzaamheden uitgebreid met de ontwikkeling van software applicaties. Om het aanbod van diensten overzichtelijk te houden, is in 2021 de onderneming onderverdeeld in twee dochterondernemingen: MaatComputers en MaatOntwikkeling. ", -1))
          ]),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.goalImage }, null, 8, _hoisted_6),
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Doelstelling", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, " Bij ComputerInfor bent u aan juiste adres voor goede service en professionele kwaliteit voor een eerlijke prijs. ComputerInfor streeft naar open communicatie, heldere afspraken en 100% klanttevredenheid. Laat uzelf overtuigen en maak een afspraak! ", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_single_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_notice_alert, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" ComputerInfor bestaat uit twee dochterondernemingen. ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_button, {
              title: "MaatComputers",
              path: "http://www.maatcomputers.nl",
              external: true
            }),
            _cache[5] || (_cache[5] = _createElementVNode("p", null, " MaatComputers doet vrijwel alles wat met computers te maken heeft: vervaardiging, revisie, onderhoud, aansluiting, advies, verkoop en nog veel meer. U kunt hier terecht voor al uw computergerelateerde problemen. ", -1))
          ]),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_button, {
              title: "MaatOntwikkeling",
              path: "http://www.maatontwikkeling.nl",
              external: true
            }),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, " MaatOntwikkeling specialiseert zich in ontwikkeling en onderhoud van kleinschalige software applicaties: websites, webapplicaties, Windows applicaties en nog veel meer. ", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}