import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "timetable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "Maandag", -1)),
      _createElementVNode("td", null, "10:00 " + _toDisplayString($data.separator) + " 20:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Dinsdag", -1)),
      _createElementVNode("td", null, "10:00 " + _toDisplayString($data.separator) + " 20:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Woensdag", -1)),
      _createElementVNode("td", null, "10:00 " + _toDisplayString($data.separator) + " 20:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Donderdag", -1)),
      _createElementVNode("td", null, "10:00 " + _toDisplayString($data.separator) + " 20:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Vrijdag", -1)),
      _createElementVNode("td", null, "10:00 " + _toDisplayString($data.separator) + " 20:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Zaterdag", -1)),
      _createElementVNode("td", null, "12:00 " + _toDisplayString($data.separator) + " 18:00", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Zondag", -1)),
      _createElementVNode("td", null, "12:00 " + _toDisplayString($data.separator) + " 18:00", 1)
    ])
  ], 512))
}