import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, _cache[0] || (_cache[0] = [
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Plaats"),
      _createElementVNode("td", null, "Brielle")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Adres"),
      _createElementVNode("td", null, "Hoogaars 80")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Postcode"),
      _createElementVNode("td", null, "3232 TG")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Telefoonnummer"),
      _createElementVNode("td", null, "+31 6 3900 8814")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "E-mailadres"),
      _createElementVNode("td", null, "info@computerinfor.nl")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "KvK-nummer"),
      _createElementVNode("td", null, "6024 9579")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Btw-id"),
      _createElementVNode("td", null, "NL00 2330 557B 49")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "IBAN"),
      _createElementVNode("td", null, "NL13 KNAB 0259 9578 28")
    ], -1)
  ])))
}